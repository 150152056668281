import React, {useContext, useEffect, useMemo, useRef, useState} from "react";

import {InfoTooltip, MaxBtn} from "../core/components";
import {LightContainer, MyModal, MyReactTable, TextfieldInput} from "../core/input_fields";
import {maxiGet, maxiPost} from "../core/maxios";
import Status from "../core/status";
import {FaCircle} from "react-icons/all";
import PersonEdit from "./personEdit";
import {Redirect} from "react-router";
import {FormContextWrapper} from "../core/form_context";
import {UserContext} from "./UserContext";
import {PersonEditWrapper} from "./personHelpers";
import {encodeGetParams} from "../core/helpers";
import Consts from "../core/consts";


export default function PersonList({match, persons, setPersons, error, loadPersons, nameCell, isClubAdmin, externalAdminEventID, judgeMode, additionalColumns, ...rest}) {
    const person_ID = parseInt(match?.params?.person_ID) || null;
    const context = useContext(UserContext);
    const [timer, setTimer] = useState(null)
    const [{loading, success, error: error2}, setStatusVar] = useState({loading: true});
    const [state, setState] = useState({})
    const [redirToNoId, setRedirToNoId] = useState(false);
    const inputRef = useRef();

    const [onlyRegistrants, setOnlyRegistrants] = useState(false);

    if (person_ID && redirToNoId) {
        return <Redirect to={"/personen"}/>
    }
    const adminMode = setPersons && (context.user?.role >= 80 || (context.user?.allPersonsPermissionTill > new Date().getTime() / 1000) || externalAdminEventID > 0);
    const searchPersons = (name, _, value) => {
        if (name === "name" && !!value) {
            timer && clearTimeout(timer)
            const t = window.setTimeout(() => {
                maxiGet("/person/search_by_name?" + encodeGetParams({name: value, externalAdminEventID}), {setStatusVar, fields: ["fullname", "dateOfBirth", "ID", "clubs", "email", "parent_ID", ...Consts.judgeFields]}).then(data => {
                    if (inputRef?.current?.value === value) {
                        setPersons(data.persons)
                    }
                })
            }, 200)
            setTimer(t)
        }
    }


    return <LightContainer name={"Personen"}>
        <div style={{float: "right", marginTop: "-60px"}}>
            {
                isClubAdmin && <><MaxBtn onClick={() => {
                    setOnlyRegistrants(!onlyRegistrants)
                    loadPersons(!onlyRegistrants)
                }}>{onlyRegistrants ? "Alle Personen anzeigen" : "Nur Meldeverantwortliche anzeigen"}</MaxBtn>&nbsp;&nbsp;</>
            }
            <MyModal trigger={<MaxBtn>Neue Person erstellen</MaxBtn>}>
                {close => <PersonEdit close={close} person={{}} reload={() => loadPersons(onlyRegistrants)}/>}
            </MyModal>
        </div>
        <Status type={"error"} text={error}/>
        <Status type={"error"} text={error2}/>
        {
            adminMode && <FormContextWrapper value={{state, setState}} onSubmit={e => e.preventDefault()} afterUpdate={searchPersons}>
                <Status type={"error"} text={error}/>
                <TextfieldInput name={"Name (Suche)"} tag={"name"} myref={inputRef}/>
            </FormContextWrapper>
        }
        <MyReactTable
            data={persons}
            columns={[
                {
                    Header: "Name",
                    accessor: "fullname",
                    Cell: nameCell || (({original, value}) => value),
                    filterable: !adminMode,
                },
                ...(state?.name?.includes("@") && persons.some(a => !!a.email) ? [{
                    Header: "E-Mail",
                    accessor: "email",
                }] : []),
                ...(additionalColumns || []),
                {
                    Header: <>V <InfoTooltip>
                        <b>V</b> falls die Person eigenständig ist, also andere Personen verwaltet. <br/>
                        <b>U</b> falls die Person nicht eigenständig ist, also von einer anderen Personen verwaltet wird, also eine Untergeordnete Person ist. <br/>
                    </InfoTooltip></>,
                    sortable: false,
                    filterable: true,
                    show: persons?.some(a => a.parent_ID !== undefined),
                    id: "admin",
                    accessor: row => row.parent_ID === null ? "V" : "U",
                    maxWidth: 46,
                },
                ...(adminMode ? [
                    {
                        Header: "V",
                        accessor: "vAmpel",
                        maxWidth: 30,
                        Cell: ({value, original}) => original.parent_ID === null && <FaCircle style={{color: {"r": "red", "o": "orange", "g": "green"}[value]}}/>,
                    },
                    {
                        Header: "U",
                        accessor: "uAmpel",
                        maxWidth: 30,
                        Cell: ({value, original}) => <FaCircle style={{color: {"r": "red", "o": "orange", "g": "green"}[value]}}/>,
                    },
                ] : []),
                /*...(!judgeMode ? [] : Consts.judgeFields.map(a => ({
                    Header: a.replace("judge", ""),
                    accessor: row => (row || {})[a] ? "J" : "",
                    filterable: true,
                    maxWidth: {judgeChM7: 55, judgeW: 30, judgeChW: 50, judgeChM: 50,}[a] || 40,
                    id: a,
                }))),*/
                {
                    Header: "Vereine",
                    id: "clubs",
                    accessor: row => row.clubs?.map ? row.clubs?.map(a => a.label).join(", ") : row.clubs,
                    show: persons.some(row => !!row?.clubs && JSON.stringify(row?.clubs) !== JSON.stringify(persons[0]?.clubs)) || persons.length === 1,
                    //Cell: ({value}) => value ? dateFormatDateInline(new Date(value)) : "",
                    filterable: true,
                },
                {
                    Header: "Jahrgang",
                    accessor: "dateOfBirth",
                    //Cell: ({value}) => value ? dateFormatDateInline(new Date(value)) : "",
                    filterable: true,
                    maxWidth: 80,
                },
                {
                    maxWidth: 200,
                    Cell: ({original, value}) => <>
                        {/*<MyModal onClose={() => setRedirToNoId(true)} defaultOpen={original.ID === person_ID} trigger={<em>bearbeiten</em>}>
                        {close => <PersonEdit close={close} person={{original}} reload={loadPersons}/>}
                    </MyModal>*/
                        }
                        <PersonEditWrapper personLight={original} isClubAdmin={isClubAdmin} reload={() => {
                            loadPersons(onlyRegistrants)
                            searchPersons("name", false, state.name)
                        }}>
                            <em>bearbeiten</em>
                        </PersonEditWrapper>
                        <em onClick={() => {
                            if (window.confirm("Person überall löschen?")) {
                                maxiPost("/person/hide", {ID: original.ID}, {setStatusVar}).then(() => {
                                    loadPersons(onlyRegistrants)
                                    searchPersons("name", null, state.name)
                                })
                            }
                        }
                        }>löschen</em>
                    </>
                }
            ]}
        />
    </LightContainer>
}
