import React, {useContext, useState, useMemo} from "react";
import {Link} from "react-router-dom";
import {Container, InfoTooltip, Loader, MaxBtn} from "../core/components";
import {EnumInput, InputContainer, LightContainer, MyModal, MyReactTable, MyTabs, SelectfieldInput, TagsInput, TextfieldInput} from "../core/input_fields";
import {FormContextWrapper} from "../core/form_context";
import {maxiGet, maxiPost} from "../core/maxios";
import Status from "../core/status";
import UserHistory from "../user/userHistory";
import Consts from "../core/consts";
import {FaCheckCircle} from "react-icons/all";
import {PermissionLevel, PersonType} from "../core/enums";
import ChangeAddressExt from "./addressExt";
import {UserContext} from "./UserContext";
import "./style.sass"
import {dateFormat, dateFormatInline} from "../core/dateFuncs";
import styles from "./Classes.module.sass"
import {encodeGetParams} from "../core/helpers";
import {PersonEditWrapper} from "./personHelpers";

const fieldsRequired = ["forename", "surname", "sex", "dateOfBirth"]


export default function PersonEdit({person, loadPerson, reload, close, isClubAdmin}) {
    const [{error, loading, success}, setStatusVar] = useState({});
    const [state, setState] = useState({person})
    const [emailValid, setEmailValid] = useState({person})
    const user = (useContext(UserContext) || {}).user || {}

    const handleSubmit = () => {
        !loading && maxiPost("/user", state.person, {setStatusVar})
            .then(() => {
                reload()
                close()
            })
    }
    const makeIndependent = () => {
        !loading && maxiPost("/person/makeIndependent", state.person, {setStatusVar})
            .then(() => {
                reload()
            })
    }
    const checkPhone = (e) => {
        const s = e.target.name.split("_");
        const val = state[s[0]][s[1]];
        if (s[1].startsWith("phone")) {

            const newVal = Consts.phoneNormalise(val)
            if (newVal !== val) {
                setState({[s[0]]: {...state[s[0]], [s[1]]: newVal}})
                //console.log(val, newVal)
            }
        }
        if (s[1] === "email") {
            maxiPost("/user/address/email", {email: val}).then(resp => {
                if (resp.email === (state[s[0]] || {}).email) {
                    setEmailValid(resp.valid)
                }
            })
        }
    };
    return <LightContainer name={"Person bearbeiten"}>
        <Status type={"error"} text={error}/>
        {
            !!state.person?.ID_ext && <>Alte ID: {state.person.ID_ext}<br/></>
        }
        Interne ID: {state.person?.ID}<br/>
        <FormContextWrapper value={{state, setState}} onSubmit={handleSubmit}>
            <div>
                {["sex", "forename", "surname", "dateOfBirth", "email", "phone", "emergencyContact", "allergies", "taID"]
                    .map((f, i) => {
                        switch (f) {
                            case "country":
                                return <InputContainer>
                                    <SelectfieldInput name={"Land"} tag={"person_" + f} selectives={[["AUT", "Österreich"], ["GER", "Deutschland"]]}/>
                                    {i % 2 === 0 && <br/>}
                                </InputContainer>
                            case "type":
                                return [<InputContainer>
                                    <EnumInput name={"Art"} tag={"person_" + f} type="reactselect" selectives={PersonType}/>
                                </InputContainer>, <br/>]
                            case "sex":
                                return [<InputContainer>
                                    <SelectfieldInput name={"Geschlecht*"} tag={"person_" + f} demandSelect selectives={[["0", "Weiblich"], ["1", "Männlich"]]}/>
                                </InputContainer>, <br/>]
                            case "email":
                                return <InputContainer>
                                    <TextfieldInput
                                        onBlur={checkPhone}
                                        ph={Consts.placeHolders[f]}
                                        inputBorderColor={emailValid === false ? "red" : null}
                                        name={<>{Consts.translate(f)} {emailValid === true && <FaCheckCircle/>}{emailValid === false && "(falsch)"}</>}
                                        tag={"person_" + f}
                                    />
                                    {(i % 2 === 1) && <br/>}
                                </InputContainer>
                            case "taID":
                                return <InputContainer>
                                    <TextfieldInput
                                        name={<>{Consts.translate(f)} <InfoTooltip>Zu finden auf mein.turnsport.at unter Turnsport-Austria Lizenzen</InfoTooltip></>}
                                        labelWidth={176}
                                        labelStyle={{paddingRight: 8}}
                                        tag={"person_" + f}
                                        ph={"123456789"}
                                    />
                                    {(i % 2 === 1) && <br/>}
                                </InputContainer>
                            case "phone":
                                return <InputContainer>
                                    <TextfieldInput
                                        ph={Consts.placeHolders[f]}
                                        onBlur={checkPhone}
                                        name={Consts.translate(f)}
                                        tag={"person_" + f}
                                    />
                                    {(i % 2 === 1) && <br/>}
                                </InputContainer>
                            /*case "dateOfBirth":
                                return <InputContainer>
                                    <DateInput name={Consts.translate(f) + "*"} tag={"person_" + f}/>
                                    {(i % 2 === 1) && <br/>}
                                </InputContainer>*/

                            case "postalCode":
                                return <>
                                    <TextfieldInput name={Consts.fieldTranslation[f] || f} tag={"person_" + f}/>
                                    {i % 2 === 1 && <br/>}
                                </>
                            case "allergies":
                                return <InputContainer>
                                    <TextfieldInput ph={Consts.placeHolders[f]}
                                                    name={<>Nahrungsmittel-Unverträglichkeiten <InfoTooltip>Bei Teilnahme an Veranstaltungen, wo Essen bereitgestellt wird, bitte hier etwaige Nahrungsmittel-Unverträglichkeiten, Allergien, Ernähungsweisen angeben.</InfoTooltip></>}
                                                    tag={"person_" + f}/>
                                    {i % 2 === 1 && <br/>}
                                </InputContainer>
                            case "emergencyContact":
                                if ((new Date().getFullYear() - state.person.dateOfBirth) > 18) {
                                    return null
                                }
                                return <InputContainer>
                                    <TextfieldInput ph={Consts.placeHolders[f]} name={<>Notfallkontakt <InfoTooltip>Bei Teilnahme an Veranstaltungen mit Übernachtung bitte Name und Telefonnummer von dem / den Erziehungsberechtigten/Notfallkontakt(en) angeben.</InfoTooltip></>} tag={"person_" + f}/>
                                    {i % 2 === 1 && <br/>}
                                </InputContainer>
                            default:
                                return <InputContainer>
                                    <TextfieldInput ph={Consts.placeHolders[f]} name={(Consts.fieldTranslation[f] || f) + (fieldsRequired.includes(f) ? "*" : "")} tag={"person_" + f}/>
                                    {i % 2 === 1 && <br/>}
                                </InputContainer>
                        }
                    })}
                <br/>
                <TagsInput multiple name={"Verein"} entity={"club"} ID={0} tag={"person_clubs"}/>

            </div>
        </FormContextWrapper>
        <ChangeAddressExt child={person} changeAddress={a => {
            setState(old => ({person: {...old.person, ...a}}))
        }}/>

        {
            (user.role > 80 || isClubAdmin) && <FormContextWrapper value={{state, setState}} onSubmit={handleSubmit}>
                {
                    state?.person?.clubs?.map(club => <>
                        <h3>Berechtigung für den {club.label}</h3>
                        <EnumInput name={"Berechtigung"} type={"reactselect"} tag={"person_permissions" + club.value} multiple selectives={PermissionLevel.filter(a => !["admin"].includes(a.value) && !a.value.includes("event"))}/>
                    </>)
                }
            </FormContextWrapper>
        }


        <div>
            <br/>
            <MaxBtn onClick={handleSubmit}>Speichern</MaxBtn><Loader loading={loading}/>
            <br/>
            {
                !!state.person.parent_ID && <>
                    <MaxBtn onClick={() => window.confirm("Zu eigenständigem Benutzer machen?") && makeIndependent()}>Zu eigenständigem Benutzer machen</MaxBtn>
                    <br/>
                </>
            }
            <div>
                {(Consts.judgeFields.some(a => person[a] !== null) || user.role > 80) && <h2>Kampfrichter-Lizenzen</h2>}
                {
                    person.taID ?
                        <DisplayJudgeLicences person_ID={person.ID}/> :
                        "Bitte zuerst die Turnsport-Austria-ID hinterlegen."
                }
            </div>
            {
                person.ID && user.role > 80 && <HistoricEventParticipation {...{person}}/>
            }
            <br/>
            {
                person.ID && user.role > 80 && <LightContainer name={"Veränderungen"}>
                    <UserHistory userID={person.ID} personType={"person"}/>
                </LightContainer>
            }
        </div>
    </LightContainer>


}


function DisplayJudgeLicences({person_ID}) {
    const [judgeLicences, setJudgeLicences] = useState(null)
    useMemo(() => maxiGet(`/person/${person_ID}/get_judge_licences`).then(setJudgeLicences), []);
    return <>
    {
        judgeLicences?.length === 0 && "Keine vorhanden."
    }
        {
            judgeLicences
                ?.sort((a, b) => a.exam_ID < b.exam_ID ? -1 : 1
                ).map(examstart => <div className={styles.judgeBoxActive}>
                <b>{examstart.resultingLicence.match(/\((.*?)\)/)[1].replace("online", "Theorie")}</b>
                &nbsp;
                {dateFormat(examstart.timeCreated)}
            </div>)
        }
    </>
}


function AddJudgeLicence({person, localClose, loadPerson}) {
    const [{error, loading, success}, setStatusVar] = useState({});
    const [state, setState] = useState({licence: {type: null}})

    return <Container name={"Kampfrichter-Stufe für " + person.fullname + " hinzufügen"}>
        <FormContextWrapper
            value={{state, setState: a => setState(b => ({...b, ...a})), updateState: (a, b, c) => setState(old => ({...old, [a]: {...old[a], [b]: c}}))}}
            onSubmit={e => {
                e.preventDefault()
                if (!!person.parent_ID && !window.confirm("Dieser Benutzer ist noch kein selbstständiger Benutzer, durch einen Klick auf fortfahren wird dieser Benutzer automatisch selbstständig.")) {
                    return
                }
                maxiPost("/person/add_judge_licence", {person_ID: person.ID, type: state.licence.type}, {setStatusVar}).then(a => {
                    loadPerson()
                    localClose()
                })
            }}
        >
            <Status type={"error"} text={error}/>
            <SelectfieldInput demandSelect name={"Typ"} tag={"licence_type"} selectives={Consts.judgeFields.map(a => [a, a.replace("judge", "")])}/>
            <MaxBtn>Hinzufügen</MaxBtn>
        </FormContextWrapper>
    </Container>
}

const alphabeth = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";

function HistoricEventParticipation({person}) {
    const {user} = useContext(UserContext);
    const [{loading, error}, setStatusVar] = useState({});
    const [data, setData] = useState({});
    const {persons, events} = data;

    useMemo(() => maxiGet("/person/events?" + encodeGetParams({person_ID: person.ID}), {setStatusVar}).then(setData), [person.ID])

    let idToLetter = {};
    for (let i = 0; i < persons?.length; i++) {
        const p = persons[i];
        idToLetter[p.ID] = alphabeth[i];
    }

    return <LightContainer name={"Vergangene Teilnahmen"}>
        <Status type={"error"} text={error}/>
        {
            persons?.length > 0 && <p>
                Mögliche Benutzer: {persons?.map((p, i) => <>
                {i > 0 && ", "} {idToLetter[p.ID]}: <PersonEditWrapper {...{personLight: p}}><em>{person.fullname}</em></PersonEditWrapper>
            </>)}
            </p>
        }
        <MyReactTable
            data={events}
            loading={loading}
            columns={[
                {
                    Header: "Name",
                    accessor: "event.name",
                    filterable: true,
                    Cell: ({original}) => user.role > 80 ? <Link to={"/veranstaltungen/" + original.event.ID}>{original.event.name}</Link> : original.event.name,
                },
                {
                    Header: "Datum",
                    id: "eventStart",
                    accessor: row => dateFormatInline(new Date(row.event.eventStart)),
                    filterable: true,
                    maxWidth: 110,
                },
                {
                    Header: "Ort",
                    accessor: "event.city",
                    filterable: true,
                    maxWidth: 100,
                },
                {
                    Header: "Person",
                    id: "person",
                    maxWidth: 80,
                    accessor: row => idToLetter[row.registered_person.person_ID],
                    filterable: true,
                },
                {
                    Header: "Art",
                    accessor: "registered_person.role.label",
                    filterable: true,
                    maxWidth: 100,
                },
                {
                    Header: "Umlauf",
                    accessor: "umlauf",
                    filterable: true,
                    maxWidth: 140,
                    Cell: ({value}) => <MyModal trigger={value}>{value}</MyModal>,
                },
                {
                    Header: "Punkte",
                    id: "finalScore",
                    accessor: r => r.participant[0]?.finalScore?.toFixed(3),
                    filterable: true,
                    maxWidth: 80,
                },
                {
                    Header: "Kampfgericht",
                    accessor: "kampfgerichte",
                    filterable: true,
                    maxWidth: 140,
                    Cell: ({value}) => <MyModal trigger={value}>{value}</MyModal>,
                }
            ]}
        />
    </LightContainer>
}
