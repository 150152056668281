import React, {useMemo, useState} from "react";
import {maxiGet, maxiPost} from "../core/maxios";
import Status from "../core/status";
import {Link} from "react-router-dom";
import {Loader, MaxBtn, MaxLink} from "../core/components";
import {LightContainer, MiniBtn, MyModal, MyReactTable} from "../core/input_fields";
import {dateFormat} from "../core/dateFuncs";
import {FaList, FaSignOutAlt, FaUserAlt, FaUsers} from "react-icons/fa";
import ShowOffersOfPerson from "../event/eventOffersOfPerson";
import {PersonEditWrapper} from "../user/personHelpers";


export default function ImportOldRegistration({event, registration, close}) {

    const [selectedEvent, setSelectedEvent] = useState(null)
    const [selectedRegistration, setSelectedRegistration] = useState(null)

    return <LightContainer>
        {
            !selectedRegistration && <SelectRegistration {...{selectedEvent, setSelectedEvent, setSelectedRegistration}}/>
        }
        {
             selectedRegistration && <SelectPersons {...{selectedRegistration, setSelectedRegistration, selectedEvent, event, registration, close}}/>
        }

    </LightContainer>
}

function SelectEvent({setSelectedEvent}) {
    const [{loading, success, error}, setStatusVar] = useState({});
    const [events, setEvents] = useState([]);
    useMemo(() => maxiGet("/event?only_permitted", {setStatusVar}).then(({events}) => setEvents(events.map(e => ({...e, eventStart: new Date(e.eventStart)})))), [])

    return <>

        <h2>Veranstaltung auswählen</h2>
        <Status type={"error"} text={error}/>
        <MyReactTable
            data={events}
            loading={loading}
            defaultSorted={[{id: "eventStart", desc: true}]}
            columns={[
                {
                    Header: "Name",
                    filterable: true,
                    accessor: "name",
                    Cell: ({original, value}) => <em onClick={() => setSelectedEvent(original)}>{value}</em>,
                },
                {
                    Header: "Start",
                    accessor: "eventStart",
                    filterable: true,
                    Cell: ({value}) => dateFormat(value),
                },
                {
                    Header: "Ort",
                    accessor: "city",
                    filterable: true,
                }
            ]}
        />
    </>
}


function SelectRegistration({selectedEvent, setSelectedEvent, setSelectedRegistration}) {
    const [{loading, success, error}, setStatusVar] = useState({});
    const [registrations, setRegistrations] = useState([])
    useMemo(() =>  maxiGet("/registration" , {setStatusVar}).then(({registrations}) => setRegistrations(registrations)), [selectedEvent?.ID])
    return <>

        <p>
            <em onClick={() => setSelectedEvent(null)}>andere Veranstaltung auswählen</em>
        </p>
        <h2>Meldung auswählen</h2>
        <Status type={"error"} text={error}/>
        <MyReactTable
            loading={loading}
            data={registrations}
            defaultSorted={[{id: "time", desc: true}]}
            columns={[
                {
                    Header: "Veranstaltung",
                    accessor: "event.name",
                    filterable: true,
                    Cell: ({value, original}) => <Link to={"/anmeldungen/" + original.registration.ID}>{value}</Link>
                },
                {
                    Header: "Registrant",
                    accessor: "owner.fullname",
                    filterable: true,
                    Cell: ({original, value}) => <PersonEditWrapper personLight={original.owner}><em>{value}</em></PersonEditWrapper>
                },
                {
                    Header: "Zeitpunkt",
                    accessor: (row)=>new Date(row.event.eventStart),
                    filterable: true,
                    id: "time",
                    maxWidth: 100,
                    Cell: ({value})=>dateFormat(value),
                },
                {
                    Header: "Verein",
                    accessor: "club.name",
                    filterable: true,
                },
                {
                    Header: "Anz.",
                    accessor: "count",
                    maxWidth: 47,
                },
                {
                    Header: "",
                    accessor: "registration.ID",
                    maxWidth: 100,
                    Cell: ({original})=><em onClick={()=>{
                        setSelectedEvent(original.event)
                        setSelectedRegistration(original)
                    }}>auswählen</em>
                },
            ]}
        />
    </>
}

function SelectPersons({selectedRegistration, setSelectedRegistration, selectedEvent, event, registration, close}) {
    const [{loading, success, error}, setStatusVar] = useState({});
    const [registeredPersons, setRegisteredPersons] = useState([]);
    const [offerSuggestionMap, setOfferSuggestionMap] = useState({});

    const [selectedRPs, setSelectedRPs] = useState([]);

    useMemo(() => selectedRegistration?.registration?.ID && maxiGet("/registration/" + selectedRegistration?.registration?.ID, {setStatusVar}).then((a) => setRegisteredPersons(a.registeredPersons)), [selectedRegistration?.registration?.ID])
    useMemo(() => selectedRegistration?.registration?.ID && maxiPost("/registration/import_old_registration_offer_suggestion", {
        old_registration_ID: selectedRegistration.registration?.ID,
        registration_ID: registration.ID,
    }, {setStatusVar}).then(setOfferSuggestionMap), [selectedRegistration?.registration?.ID])


    return <>

        <p>
            <em onClick={() => setSelectedRegistration(null)}>andere Meldung auswählen</em>
        </p>
        <h2>{selectedEvent.name}, {selectedRegistration.clubs}, Personen auswählen</h2>
        <Status type={"error"} text={error}/>
        <MaxBtn onClick={() => registeredPersons.length === selectedRPs.length ? setSelectedRPs([]) : setSelectedRPs(registeredPersons.map(a => a.registeredPerson.ID))}>alle auswählen</MaxBtn>
        &nbsp;
        &nbsp;
        <MaxBtn onClick={() => !loading && maxiPost("/registration/import_old_registration", {
            old_registration_ID: selectedRegistration.registration.ID,
            registration_ID: registration.ID,
            selected_rps: selectedRPs,
        }, {setStatusVar}).then(close)}>importieren</MaxBtn>



        <MyReactTable
            data={registeredPersons}
            defaultSorted={[{id: "clubs"}]}
            loading={loading}
            columns={[
                {
                    maxWidth: 30,
                    Cell: ({original}) => {
                        const key = original.registeredPerson.ID;
                        return <input type={"checkbox"} checked={selectedRPs.includes(key)} onClick={(e) => {
                            if (!selectedRPs.includes(key)) {
                                setSelectedRPs([...selectedRPs, key])
                            } else {
                                setSelectedRPs(selectedRPs.filter(a => a !== key))
                            }
                        }}/>
                    },
                },
                {
                    Header: "Name",
                    filterable: true,
                    accessor: "person.fullname",
                },
                {
                    Header: "Teilnahme-Art",
                    filterable: true,
                    maxWidth: 90,
                    id: "role",
                    accessor: row => row.registeredPerson.role.value === "official" ? selectedEvent.allowOfficialsLabel : row.registeredPerson.role.label,
                },
                {
                    Header: "Name",
                    filterable: true,
                    maxWidth: 60,
                    accessor: "person.dateOfBirth",
                },
                {
                    Header: "alte Zuordnung",
                    filterable: true,
                    maxWidth: 60,
                    Cell: ({original}) => <MyModal trigger={<em><FaList/> ({original.offersCount || 0})</em>}>
                        <ShowOffersOfPerson event={selectedEvent} registration={selectedRegistration?.registration} person={original.person}/>
                    </MyModal>
                },
                {
                    Header: "neue Zuordnung",
                    filterable: true,
                    id: "newAssociation",
                    accessor: row=>offerSuggestionMap[row.registeredPerson.ID]?.join(", "),
                }
            ]}
        />


    </>
}
